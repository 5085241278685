:root,
::after {
  --color-primary: theme('colors.orange');
  --color-secondary: theme('colors.lightBlue.1');
}
.btn,
.btn-icon {
  &.primary {
    @apply bg-orange text-white border-orange;
    &.outlined {
      @apply bg-white text-orange border border-orange;
    }
    &.link {
      @apply bg-transparent text-orange border-transparent shadow-none;
    }
  }

  &.secondary {
    @apply bg-secondaryBlue-500 text-white;
    &.outlined {
      @apply bg-transparent border-secondaryBlue-500 border text-secondaryBlue-500;
    }
    &.link {
      @apply bg-transparent text-secondaryBlue-500 border-transparent shadow-none;
    }
  }
}
.bg-orange-gradient {
  background: url('/assets/images/SHORTCUT_2.svg') no-repeat right bottom,
    linear-gradient(90deg, #fb7200 48.96%, #de6501 89.95%);
  background: url('/assets/images/SHORTCUT_2.svg') no-repeat right bottom,
    -moz-linear-gradient(90deg, #fb7200 48.96%, #de6501 89.95%);
  background: url('/assets/images/SHORTCUT_2.svg') no-repeat right bottom,
    -webkit-linear-gradient(0deg, #fb7200 48.96%, #de6501 89.95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FB7200",endColorstr="#DE6501",GradientType=1);
}

.btn-extra-large {
  @apply p-6 h-20 rounded-2xl;
  span {
    @apply text-base font-bold;
  }
  i::before {
    @apply text-2xl;
  }
  &:disabled {
    @apply text-blueGray-400;
    background: url('/assets/images/SHORTCUT_2.svg') no-repeat right bottom, var(--color-blueGray-500);
  }
}

.bg-blue-gradient {
  background: url('/assets/images/SHORTCUT.svg') no-repeat right bottom,
    linear-gradient(90deg, #27b1ff 48.96%, #315d93 89.95%);
  background: url('/assets/images/SHORTCUT.svg') no-repeat right bottom,
    -moz-linear-gradient(90deg, #27b1ff 48.96%, #315d93 89.95%);
  background: url('/assets/images/SHORTCUT.svg') no-repeat right bottom,
    -webkit-linear-gradient(0deg, #27b1ff 48.96%, #315d93 89.95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#27B1FF",endColorstr="#315D93",GradientType=1);
  &:disabled {
    background-color: #64748b;
    border-color: #64748b;
  }
}

.bg-shortcut {
  @apply bg-[url('/assets/images/SHORTCUT.svg')] bg-right-bottom bg-no-repeat;
  &.shortcut2 {
    @apply bg-[url('/assets/images/SHORTCUT_2.svg')];
  }
}

.learning-goal-item {
  @apply flex h-[100px] items-center rounded-2xl bg-white shadow-1 md:shadow-3 px-4 py-2 gap-4 cursor-pointer;
  img {
    @apply h-20 w-20 rounded-2xl;
  }
}

.shine {
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    var(--color-blueGray-400) 0%,
    var(--color-primaryBlue) 30%,
    var(--color-blueGray-400) 100%
  );
  &.orange,
  &.primary {
    background-image: linear-gradient(
      to right,
      theme('colors.lightOrange.5') 0%,
      theme('colors.lightOrange.3') 30%,
      theme('colors.lightOrange.5') 100%
    );
  }
  &.secondary {
    background-image: linear-gradient(
      to right,
      theme('colors.secondaryBlue.200') 0%,
      theme('colors.secondaryBlue.400') 30%,
      theme('colors.secondaryBlue.200') 100%
    );
  }
}
[data-skin='default'][data-theme='light'] {
  .tab-wrapper {
    &.primary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-lightOrange-4 md:bg-blueGray-200;
      }
      &.active {
        @apply md:text-orange bg-lightOrange-4 md:bg-white text-orange;
      }
    }
    &.secondary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-lightBlue-6 md:bg-blueGray-200;
      }
      &.active {
        @apply md:text-lightBlue-1 bg-lightBlue-6 md:bg-white text-lightBlue-1;
      }
    }
  }
}
[data-skin='default'][data-theme='dark'] {
  .tab-wrapper {
    &.primary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-darkOrange-4 md:bg-primaryBlue-700;
      }
      &.active {
        @apply md:text-orange bg-lightOrange-4 md:bg-primaryBlue-800 text-orange;
      }
    }
    &.secondary .tab-item {
      &:not(.active) {
        @apply md:hover:bg-primaryBlue-600 md:bg-primaryBlue-700;
      }
      &.active {
        @apply md:text-lightBlue-1 bg-lightBlue-6 md:bg-primaryBlue-800 text-lightBlue-1;
      }
    }
  }
}
